import React from "react";


type Props = {
    name: string;
    title: string;
    topText?: string;
    subtitle?: string;
    children?: React.ReactNode;
}

export const Section = ({name, title, subtitle, topText, children}: Props) => {
    const content = <>
        <div className="section-header" id={`Section-${name}-header`}/>

        {topText && <h1>{topText}</h1>}
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}

        {children || <></>}
    </>;

    const className = `${name} section`;
    const id = `Section-${name}`;


    if (name === "Header") return <header className={className} id={id}>{content}</header>

    return <div className={className} id={id}>{content}</div>
}
