import {Item} from "./Types";
import React from "react";


export const Article = ({item, i}: { item: Item, i: number }) => {

    const open = (url: string) => {
        window.open(url, '_blank');
    }


    return <div className="article" key={i} onClick={() => open(item.url)}>
        <div className="article-upper">
            <h3>{item.title}</h3>
            <span>{i + 1}</span>
        </div>

        <div className="article-lower">
            <div>
                <span>{item.author}</span>
                <span>{item.publishDate}</span>
            </div>

            <img src={item.image} alt={item.title}/>
        </div>
    </div>
}
