import React from "react";


type Props = {
    title: string;
    client: string;
    icon: string;
}


export default function Crisis({title, client, icon}: Props) {

    return <div className="Crisis">
        {/*<img src={`/assets/${icon}`} alt="icon"/>*/}
        <h3>{title}</h3>
        <p>{client}</p>

        <img src={`/icons/check.svg`} alt="icon"/>
    </div>
}
