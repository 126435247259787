import React from 'react';


type Props = {
    icon: string;
    title: string;
    text: string;
}

export default function AboutBox({icon, title, text}: Props) {

    return <div className="AboutBox">
        <img src={`/icons/${icon}`} alt={title}/>
        <h3>{title}</h3>
        <p>{text}</p>
    </div>
}
