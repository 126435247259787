import React from 'react';


type Props = {
    link: string;
    title: string;
    source: string;
    author?: string;
    subtitle: string;
}

export default function ArticleBox({title, subtitle, source, link, author = "פנינה שלו"}: Props) {

    const sourceToImage = (source: string) => {
        if (source === "ynet")
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSN95gF-iSeIWyOLqoDvxEbcrHw-db0IZayVg&s";

        if (source === "calcalist")
            return "https://upload.wikimedia.org/wikipedia/he/thumb/d/db/Calcalist.svg/2560px-Calcalist.svg.png"

        if (source === "walla")
            return "https://upload.wikimedia.org/wikipedia/he/thumb/9/9c/%D7%A1%D7%9E%D7%9C%D7%99%D7%9C_%D7%95%D7%95%D7%90%D7%9C%D7%94%21.svg/2560px-%D7%A1%D7%9E%D7%9C%D7%99%D7%9C_%D7%95%D7%95%D7%90%D7%9C%D7%94%21.svg.png";

        if (source === "globes")
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVLvJBRls2rvh0Zwqki6eCGoPCpGIVaCYMUQWwhd6BWYV67qMzPeVlrJvgepRzIC0GnA&usqp=CAU";

        if (source === "mako")
            return "https://upload.wikimedia.org/wikipedia/he/archive/a/ab/20190504132051%21Mako_keshet.svg";

        if (source === "maariv")
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC0IxKw1M9Ud3zLgWI7vMxJiUEpfpseEqk4w&s";

        if (source === "haaretz")
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLIoKnk0BNgJGdGkTwHOk3Cr7FmTdm2cYeOQ&s";

        if (source === "themarker")
            return "https://upload.wikimedia.org/wikipedia/he/thumb/b/b3/TheMarker_Logo.svg/2560px-TheMarker_Logo.svg.png";

        if (source === "IAS")
            return "https://www.ias.co.il/wp-content/uploads/2018/01/logo.png";

        if (source === "Passport News")
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQeIZdhBzjhmPejqGHiGHAi-IElKa_1KL37A&s";

        if (source === "hamal")
            return "https://play-lh.googleusercontent.com/Pb0AhJiVX8zwYODIVC_PvUpucMYsa7wR1yzybQ3pS1cb192V00pHZNVQd3R4pPHSmWY";

        if (source === "ice")
            return "https://upload.wikimedia.org/wikipedia/commons/8/8d/Ice_co_il_logo.png";

        return "https://media.istockphoto.com/id/1162198273/vector/question-mark-icon-flat-vector-illustration-design.jpg?s=612x612&w=0&k=20&c=MJbd8bw2iewJRd8sEkHxyGMgY3__j9MKA8cXvIvLT9E=";
    }


    return <div className="Article" onClick={() => window.open(link)}>
        <div className="upper">
            <h3>{title}</h3>
        </div>

        <div className="lower">
            <p>{subtitle}</p>

            <div>
                <span>
                    נכתב על ידי {author}
                </span>

                <img src={sourceToImage(source)} alt="source"/>
            </div>
        </div>
    </div>
}