
type Language = "en" | "he";


type TextType = {
    Header: {
        companyName: string;
        companySlogan: string;
        headerExtraText: string;
    },

    About: {
        title: string;
        boxes: {
            icon: string;
            title: string;
            text: string;
        }[];
    },

    CrisisManagement: {
        title: string;
        subtitle: string;
        crisis: {
            title: string;
            client: string;
        }[];
    },

    Articles: {
        title: string;
        subtitle: string;
        articles: {
            link: string;
            title: string;
            source: string;
            author?: string;
            subtitle: string;
        }[];
    },

    Struggles: {
        title: string;
        subtitle: string;
        struggles: {
            title: string;
            client: string;
        }[];
    },

    Contact: {
        title: string;
        subtitle: string;
        contactText: string;
        phone: string;
        email: string;
        email2: string;
        whatsapp: string;
    },

    Navigation: {
        about: string;
        crisisManagement: string;
        articles: string;
        struggles: string;
        contact: string;
        whatsapp: string;
    }
};


export type Page = { name: string, link: string };


export const getLanguage = (): Language => {
    return "he";

    const url = window.location.pathname;
    if (url.startsWith("/en") || url.startsWith("/english"))
        return "en";

    if (url.startsWith("/he") || url.startsWith("/hebrew"))
        return "he";

    return "he";
}


export const Hebrew = {
    Header: {
        companyName: "שלו יחסי ציבור ואסטרטגיה",
        companySlogan: "שירותי דוברות, ייעוץ תקשורתי, ניהול מאבקים ציבוריים וניהול משברים",
        headerExtraText: "כאן יוכל להיות טקסט נוסף בכותרת",
    },

    About: {
        title: "אודות",
        boxes: [
            {
                icon: "news.svg",
                title: "יחסי ציבור ודוברות",
                text: `לספר סיפור מעניין, מוצלח ומהודק, שמביא לחשיפה אפקטיבית ומקדם רעיונות ויעדים מטעם הלקוח - זוהי בתמצית עבודת הדוברות.
המשרד מלווה לאורך שנים, ובהצלחה, אישים, גורמים וגופים מכלל המגזרים (ציבורי, מגזר שלישי ועסקי), ופועל תחת המוטו: יגעת ומצאת - האמיני! יחסי ציבור אינם ריצת ספרינט, אלא מסע רציני ומתמשך, ריצת מרתון יד ביד עם הלקוח. עבודה מקצועית, נחושה, אפקטיבית ויצירתית, תוך מציאת פתרונות אד הוק למצבים לא צפויים, מביאה לתוצאות מוצלחות.
כבר חצי יובל מקודמים לקוחות מרוצים של המשרד מעל במות תקשורתיות ואחרות, פשוט כיף!`
            },

            {
                icon: "socials.svg",
                title: "דיגיטל ותוכן",
                text: `ממשרד יחסי ציבור איכותי מצופה לספר את הסיפור של הלקוח בצורה מדויקת ומעניינת, בין אם מול אמצעי תקשורת מסורתית, ובין אם במדיה הדיגיטלית וברשתות החברתיות.
הסנכרון בין המדיות, ההקפדה על שפה אחידה, על זהות מותגית ועל הידוק המסרים, באמצעים יצירתיים ומקצועיים, הם חלק שוטף מעבודת אנשי יחסי הציבור והדוברות.
כמשרד, אנחנו מנהלים באופן מלא חלק מהפלטפורמות הדיגיטליות של לקוחותינו, ולעיתים רק שותפים בניהול הדיגיטלי ובבנית אסטרטגיות פעילות דיגיטלית אצל לקוחות אחרים.   
אלה הם עולמות דינאמיים ואנחנו מתאימים את פעילות המשרד לצרכי הלקוחות שלנו.`
            },

            {
                icon: "protest.svg",
                title: "מאבקים ציבורים",
                text: `שלו יחסי ציבור ואסטרטגיה היא חברת בוטיק בתחום ה-Public affairs  ונחשבת כבעלת ניסיון רב, ייחודי ומוצלח במאבקים ציבוריים מורכבים ומאתגרים, עם פרופיל תקשורתי גבוה, של רשויות, איגודים, וועדים וארגונים. 
מאבקים ציבוריים מתאפיינים בהתנהלות מקבילה בזירות שונות, שמחייבת סנכרון מלא. בין היתר: משפטית, רגולטורית ותקשורתית (כאשר התקשורת היא אמצעי, ולא מטרה, להפעלת לחץ לקידום מטרות ויעדים). 
כדי להצליח במאבק ציבורי, ולהביא לשינוי, נדרש ניסיון מקצועי רב ונדרשת יציבות וביטחון.
אנחנו מספקים מעטפת מקצועית כוללת, מלווה במוטיבציה גבוהה ובתחושות שליחות, יחד עם רגש תחרותיות להצלחה.`
            },

            {
                icon: "crisis.svg",
                title: "ניהול משברים",
                text: `כרעם ביום בהיר צץ משבר ציבורי/מסחרי/תקשורתי (אם כי, לעיתים מתפתח בהדרגה). עבודת דוברות ויחסי ציבור חכמה ונכונה - חיונית למזעור נזקים, ולעיתים מצליחה להפוך לימון ללימונדה, אירוע קשה - להזדמנות.
המפתחות לניהול חכם של משבר הם: 1. מיפוי מהיר של זירות, סיכונים וסיכויים. 2. מהירות תגובה: הבנת חוקי המשחק – צורך בזמינות מידע בפלטפורמות התקשורתיות ובתגובתיות מואצת 2. שקיפות: סופם של שקרים והטיות מידע לצוף ולהתגלות. כדי לייצר צעדי אמון, נדרשת עבודת דוברות מדויקת והגונה, שתקטין להבות 3. מהלכים אסטרטגיים צופי פני העתיד (הקדמת תרופה למכה).`
            },
        ]
    },

    CrisisManagement: {
        title: "ניהול משברים",
        subtitle: `אפשר לכתוב הררי מילים בעניין ניהול משברים ציבוריים ותקשורתיים, אפשר להרחיב עד כמה חשוב ניסיון. ביטחון. שקט. מסירות. הירתמות 24/7. יש משרדי יח"צ, שיודעים להקטין להבות, 
ויש שעושים שואו אוף, ואז שופכים דליי מים...
אנחנו? שייכים לסוג הראשון:`,

        crisis: [
            {
                title: "שביתות הרופאים",
                client: "משרד רה״מ, הליך הגישור בין האוצר לרופאים",
            },

            {
                title: "הסרת איום האוצר להטלת מע״מ על התיירות הנכנסת",
                client: "התאחדות המלונות בישראל",
            },

            {
                title: "הסרת האיום להרוס את מלונות ים המלח",
                client: "התאחדות מלונות ים המלח",
            },

            {
                title: "תחלואת פוליו",
                client: "משרד הבריאות, תחת השרה: יעל גרמן",
            },

            {
                title: "משבר אמון בתמ״לים",
                client: "פרומדיקו, יבואנית סימילאק בעת משבר רמדיה!",
            },

            {
                title: "קיצוץ תקציב ארגוני הנוער",
                client: "מועצת ארגוני הנוער",
            },

            {
                title: "קורונה - פגיעה כלכלית קשה בטבריה",
                client: "התאחדות המלונות בטבריה",
            },

            {
                title: "קורונה - המנעות מהגעת נפגעי שבץ מוחי לבתי\"ח",
                client: "האיגוד הישראלי לנוירולוגיה",
            },
        ]
    },

    Articles: {
        title: "פרסומים",
        subtitle: "פרסומים בתקשורת על פעילות המשרד ו/או פרסומים מטעם המשרד.",

        articles: [
            {
                source: "ynet",
                title: "אל תספרי לאף אחד על החולה ששכחנו להחזיר מבדיקה!",
                subtitle: "הטעויות בגלל העומס, הנזיפות על זה שאין חולים במסדרון, המנהלים שחיים בפחד, הקשיש שימות כי אין ידיים לטפל בו. מאחורי חומת השתיקה: העדויות של הרופאים שחוששים לדבר על מה שבאמת קורה בבתי החולים",
                link: "https://www.ynet.co.il/articles/0,7340,L-5488276,00.html",
            },

            {
                source: "calcalist",
                title: "סימון המזון: דרושה השלמת הרפורמה",
                subtitle: "משרד הבריאות, שעושה בינתיים עבודה מצוינת, צריך לתקן פה ושם עיוותים, לפעול להפעלתה גם בבתי קפה ובמסעדות ולהשלים את את הצעדים החוקיים כדי להגן על הילדים מפני מזון לא בריא",
                link: "https://www.calcalist.co.il/local/articles/0,7340,L-3785411,00.html"
            },

            {
                source: "ynet",
                title: "הצנזורה המפחידה של מנהלי בתי החולים",
                subtitle: "מנהלי המחלקות הפנימיות נאבקים במשבר במערכת הבריאות, אבל חלק מבתי חולים מונעים מהם להתראיין ולא מאפשרים כניסת מצלמות. למה?",
                link: "https://www.ynet.co.il/articles/0,7340,L-5454520,00.html"
            },

            {
                source: "themarker",
                title: "האם הממשלה הבינה כבר את החשיבות של מערכת הבריאות?",
                subtitle: "הרופאים, ששמעו בעבר שוב ושוב, על קשיי תקציב, מגלים כי כשהמדינה רוצה וחייבת - נמצאים התקציבים, תהליכים מתקדמים מהר ונוצרות בשטח עובדות חדשות",
                link: "https://www.themarker.com/opinion/2020-04-21/ty-article/.premium/0000017f-e0f6-d75c-a7ff-fcffd0fe0000"
            },

            {
                link: "https://hamal.co.il/main/4-%D7%A4%D7%A6%D7%95%D7%A2%D7%99%D7%9D-%D7%94%D7%92%D7%99%D7%A2%D7%95-%D7%9C%D7%9E%D7%A8%D7%9B%D7%96-%D7%94%D7%A8%D7%A4%D7%95%D7%90%D7%99-%D7%91%D7%92%D7%9C%D7%99%D7%9C-%D7%9B%D7%AA%D7%95%D7%A6%D7%90%D7%94-%D7%9E%D7%94%D7%9E%D7%98%D7%97-%D7%9C%D7%A6%D7%A4%D7%95%D7%9F-%D7%94%D7%91%D7%95%D7%A7%D7%A8-127412",
                title: "4 פצועים הגיעו למרכז הרפואי בגליל כתוצאה מהמטח לצפון הבוקר",
                subtitle: `למרכז הרפואי לגליל הגיעו בשעות האחרונות 4 פצועים.

אחד מהם הגיע במצב קשה, פצוע רסיסים והדף. נמצא כרגע בחדר ניתוח.
שלושה נוספים, נפגעי שאיפת עשן במצב קל.
עדכונים בהמשך.
פנינה שלו ריכוז דוברות והסברה המרכז הרפואי לגליל`,
                author: "מערכת חמ\"ל",
                source: "hamal"
            },

            {
                source: "maariv",
                title: "אחרי עשור של מאבק, איך ייתכן ששוב הופתענו מהצורך בקיצור תורנויות המתמחים",
                subtitle: "המאבק לקיצור התורנויות של המתמחים פוגש מערכת בריאות שחוקה, שנמצאת מעבר לקצה. אלא שהיה לנו עשור להיערך לכך ולהשקיע בזה חשיבה ובזבזנו אותו",
                link: "https://www.maariv.co.il/journalists/opinions/Article-870048"
            },

            {
                source: "ynet",
                title: "אחרי הקורונה: איך זה שאף אחד לא רוצה את משרד הבריאות?",
                subtitle: "מחסור במיטות אשפוז, תורנויות של 26 שעות ברצף ותקנים חסרים לרופאים הם רק חלק מבעיותיה של מערכת הבריאות, שרק מתחילה להתאושש משנת הקורונה העמוסה. אבל בניגוד לדרישת תיקי האוצר, הביטחון, המשפטים והחינוך, שהם סחורה חמה עבור הפוליטיקאים, אף אחד לא רוצה את משרד הבריאות.",
                link: "https://www.ynet.co.il/health/article/BJRINSLuu"
            },

            {
                link: "https://www.ice.co.il/pr-sales-promotions/news/article/767714",
                source: "ice",
                title: "היחצניות המובילות והמשלמות: פרויקט התוכן השיווקי של מגזין 'שמנת' מעורר ביקורת",
                subtitle: "פגיעה באמינות, הטעיית קוראים וסקסיזם - אלו חלק מהביקורות כלפי פרויקט 'היחצניות המושכות בחוטים' של המגזין מקבוצת 'הארץ', שדרש כ-4,000 ש' מכל משתתפת",
                author: "משה בנימין"
            },

            {
                source: "mako",
                title: "אחרי עשור של מאבק, איך ייתכן ששוב הופתענו מהצורך בקיצור תורנויות המתמחים",
                subtitle: "המאבק לקיצור התורנויות של המתמחים פוגש מערכת בריאות שחוקה, שנמצאת מעבר לקצה. אלא שהיה לנו עשור להיערך לכך ולהשקיע בזה חשיבה ובזבזנו אותו",
                link: "https://www.mako.co.il/health-illnesses-and-medicines/illnesses/Article-ccdf2a1634e5d61027.htm"
            },

            {
                source: "walla",
                title: "אחרי השבחים והמטסים, המערכת שוב זונחת את הרופאים",
                subtitle: "\"הרעיפו עלינו מחמאות, הודו לנו בשלטי החוצות ובמטסים ביום העצמאות, מתנדבים הביאו לנו משלוחי מזון טעימים ורצו רק לפנק אותנו. חשנו פתאום אהודים ומקובלים, והנה זה מסתיים. סגירת המחלקות הייעודיות לחולי הקורונה, והחזרה לשגרת העבודה במחלקות הפנימיות, היא נחיתה כואבת לקרקע המציאות הקשה והמתסכלת\".",
                link: "https://news.walla.co.il/item/3355123"
            },

            {
                source: "calcalist",
                title: "עובדי המעבדות הציבוריות שקופים מדי",
                subtitle: "ביום ראשון הקרוב תפרוץ שביתת עובדי המעבדות הציבוריות, והארץ די שותקת. משמעות השביתה היא לא רק פגיעה במאמצי המאבק בקורונה; איך אפשר לשחרר תינוקות יילודים מבתי החולים, ללא בדיקות דם ראשוניות? או לבצע טיפולים רפואיים שוטפים? ייתכן שהרקע לאדישות היא המחשבה השגויה ש\"זה לא יקרה\". אם כן, זו טעות",
                link: "https://www.calcalist.co.il/local/articles/0,7340,L-3846811,00.html"
            },

            {
                link: "https://www.ice.co.il/hotnews/news/article/293611",
                title: "הליך ההדברות בין האוצר לרופאים",
                subtitle: "הליך ההדברות בין האוצר לרופאים החל הבוקר בפגישה במשרדי ההסתדרות הרפואית ברמת גן. דוברת המגשרים החדשה היא פנינה אלעזר-שלו מ'שלו תקשורת'",
                author: "דפנה פלס",
                source: "ice"
            },

            {
                source: "globes",
                title: "האגו של היחצ\"ן",
                subtitle: "חיילים אלמונים נלחמים לצד היחצ\"נים של המדינה: מוטי שרף, רני רהב ואייל ארד",
                link: "https://www.globes.co.il/news/article.aspx?did=1000537253"
            },

            {
                source: "IAS",
                title: "איגוד בתי המלון והתיירנים בחר ב'שלו תקשורת' כמשרד יחסי הציבור",
                subtitle: `איגוד בתי המלון והתיירנים, ומאגד בתי מלון עצמאיים, בתי הארחה בקיבוצים והוסטלים, בחר בפנינה שלו, ממשרד 'שלו יחסי ציבור ואסטרטגיה' למתן שירותי יחסי ציבור ודוברות.
שלו שימשה בעבר, משך כ16 שנים, כדוברת ואשת יחסי הציבור של התאחדות המלונות בישראל. היא מכירה היטב את עולם המלונאות הישראלי, ואת האתגרים והחסמים שבפניו.`,
                author: "מערכת אתר IAS",
                link: "https://www.ias.co.il/%d7%9e%d7%9c%d7%95%d7%a0%d7%95%d7%aa/%d7%90%d7%99%d7%92%d7%95%d7%93-%d7%91%d7%aa%d7%99-%d7%94%d7%9e%d7%9c%d7%95%d7%9f-%d7%95%d7%94%d7%aa%d7%99%d7%99%d7%a8%d7%a0%d7%99%d7%9d-%d7%91%d7%97%d7%a8-%d7%91%d7%a9%d7%9c%d7%95-%d7%aa%d7%a7%d7%a9/"
            },

            {
                link: "https://www.calcalist.co.il/local/articles/0,7340,L-3601445,00.html",
                title: "יעל גרמן מעסיקה דוברת שמחזיקה במקביל לעבודתה משרד יח\"צ",
                subtitle: "הדוברת, פנינה שלו, דבררה בעבר לקוחות בתחום הבריאות, ובימים אלו מייחצנת בין השאר את התאחדות המלונות. שלו: \"המינוי אושר כחוק, אינני עוסקת ביח\"צנות בנושאי בריאות\"",
                author: "תומר אביטל",
                source: "calcalist",
            },

            {
                source: "ice",
                link: "https://www.ice.co.il/advertising-marketing/news/article/849918",
                title: "פנינה שלו מקבלת תקציב חדש: זו חברת התיירות שהצטרפה",
                subtitle: "קבוצת התיירות הצומחת ג'נסיס תיירות, בחרה במשרד יחסי הציבור: 'שלו יחסי ציבור ואסטרטגיה', בבעלות פנינה שלו, להוביל את פעילותה התקשורתית",
                author: "מערכת ice",
            },

            {
                link: "https://passportnews.co.il/%D7%93%D7%95%D7%91%D7%A8%D7%AA-%D7%94%D7%AA%D7%90%D7%97%D7%93%D7%95%D7%AA-%D7%94%D7%9E%D7%9C%D7%95%D7%A0%D7%95%D7%AA-%D7%91%D7%99%D7%A9%D7%A8%D7%90%D7%9C-%D7%9E%D7%A1%D7%99%D7%99%D7%9E%D7%AA-%D7%90",
                title: "דוברת התאחדות המלונות בישראל מסיימת את תפקידה",
                subtitle: "דוברת התאחדות המלונות בישראל, פנינה אלעזר שלו, מסיימת את תפקידה בהתאחדות. שלו: \"אני מודה לאנשי צוות ההתאחדות על האמון הרב, שנתנו בי לאורך השנים\"",
                author: "עדי אזולאי",
                source: "Passport News"
            },
        ],
    },

    Struggles: {
        title: "ניהול מאבקים",
        subtitle: "בקצרה. אלה מאבקים לדוגמה, תחת כנפי המשרד:",


        struggles: [
            {
                title: "גדר הפרדה לישראל!",
                client: "התנועה להיפרדות דו צדדית + המועצה לשלום ולביטחון",
            },

            {
                title: "הפחתת שימוש בפלסטיק בבקבוקי תינוקות",
                client: "promedico",
            },

            {
                title: "שמים פתוחים",
                client: "התאחדות המלונות בישראל",
            },

            {
                title: "תקציבי שיווק לישראל",
                client: "התאחדות המלונות בישראל",
            },

            {
                title: "סימון מזון",
                client: "עמותת מהיום, לאורח חיים בריא ומניעת סוכרת",
            },

            {
                title: "מס סוכר",
                client: "עמותת מהיום, לאורח חיים בריא ומניעת סוכרת",
            },

            {
                title: "תו אפשריבריא",
                client: "אפשריבריא, התכנית הלאומית לחיים פעילים, ובריאים, עבור משרדי הבריאות, החינוך והספורט",
            },

            {
                title: "מאבק שכר במעבדות הרפואיות הציבוריות",
                client: "הסתדרות עובדי המעבדות הציבוריות",
            },

            {
                title: "הקשישה במסדרון",
                client: "האיגוד הישראלי לרפואה פנימית",
            },
        ]
    },

    Contact: {
        title: "צרו קשר",
        subtitle: "כותרת משנה כאן",
        contactText: "כאן יכול להיות טקסט נוסף בכותרת",
        phone: "054 - 7400740",
        email: "shalevpr@gmail.com",
        email2: "shalevp@bezeqint.net",
        whatsapp: "https://wa.me/9720547400740"
    },

    Navigation: {
        about: "אודות",
        crisisManagement: "ניהול משברים",
        articles: "פרסומים",
        struggles: "מאבקים",
        contact: "צרו קשר",
        whatsapp: "וואטסאפ",
    }
} as TextType;


export const Text: TextType = getLanguage() === "he" ? Hebrew : Hebrew; // English;
