import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import CollectionIndex from "./Collections/CollectionIndex";
import ReactDOM from 'react-dom/client';
import App from './App';

import './Collections/Collection.scss';
import './index.scss';


const div = document.getElementById('root') as HTMLDivElement;
const root = ReactDOM.createRoot(div);


root.render(<BrowserRouter>
    <Routes>
        <Route path='/collection/l2024m' element={<Navigate to='/collection/l2023-24d'/>}/>
        <Route path='/collections/*' element={<Navigate to='/collection'/>}/>
        <Route path='/collection/*' element={<CollectionIndex/>}/>
        <Route path='/' element={<App/>}/>

        <Route path='*' element={<Navigate to='/'/>}/>
    </Routes>
</BrowserRouter>);
