import {getCollectionDates} from "./Database";
import {CollectionListItem} from "./Types";
import {Helmet} from "react-helmet";
import {Article} from "./Article";
import {useEffect} from "react";
import {Text} from "../Global";


export const Collection = ({collectionItem}: { collectionItem: CollectionListItem }) => {
    const {collection} = collectionItem;
    const client = collection.client;

    const items = collection.items;

    items.sort((a, b) => {
        const dateA = a.publishDate.split("/").reverse().join("");
        const dateB = b.publishDate.split("/").reverse().join("");

        return dateA.localeCompare(dateB) || a.title.localeCompare(b.title);
    });

    const date = getCollectionDates(collection);

    const desc = () => {
        const collection_ = "לקט תקשורת";
        const for_ = "עבור"
        const text_ = "הלקט מכיל כתבות ופרסומים שונים שהתפרסמו במגוון עיתונים ואתרי חדשות."

        return `${collection_} ${date} ${for_} ${client.name} | ${text_}`
    }

    const description = document.getElementById("description") as HTMLMetaElement;
    description.content = desc();


    function scrollUp() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const goUp = document.getElementById("go-up") as HTMLDivElement;

            if (window.scrollY > window.innerHeight) {
                goUp.classList.add("go-up-visible");
                goUp.classList.remove("go-up-hidden");
            }

            else {
                goUp.classList.add("go-up-hidden");
                goUp.classList.remove("go-up-visible");
            }
        });
    }, []);


    return <div className="collection">
        <Helmet>
            <title>{"לקט תקשורת | "}{collection.title}</title>
            <meta name="description" content={desc()}/>
            <link rel="icon" href={client.favicon || client.logo} type="image/png"/>
            <link rel="apple-touch-icon" href={client.favicon || client.logo} type="image/png"/>
        </Helmet>

        <header className="section">
            <div className="logos">
                <img className="shalevpr-logo" src="/assets/full-logo.png" onClick={() => window.open("https://shalevpr.icu")} alt="לוגו"/>
                <img className="client-logo" title={client.name} src={client.logo} onClick={() => window.open(client.url)} alt="לוגו"/>
            </div>

            <h1>{"לקט תקשורת - "}{collection.title}</h1>


            <span>{date}</span>
        </header>

        <main>
            <span>כדי לפתוח את הכתבה, לחצו עליה.</span>

            <div className="articles">
                {items.reverse().map((item, index) => <Article key={index} item={item} i={index}/>)}
            </div>
        </main>

        <footer>
            <div onClick={() => window.open("https://shalevpr.icu")}>
                <img className="client-logo" src="/assets/logo.png" alt="לוגו"/>
                <span>{Text.Header.companyName}</span>
            </div>

            <div onClick={() => window.open(collection.client.url)}>
                <img src={collection.client.logo} alt="לוגו"/>
                <span>{collection.client.name}</span>
            </div>
        </footer>

        <div id="go-up" className="go-up" onClick={scrollUp}>
            <img src="/icons/arrow-up.svg" alt="חזרה למעלה"/>
        </div>
    </div>
}
